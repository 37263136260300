import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/fichas/tipos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(tipo) {
    return apiClient.patch(`/${tipo.id}`, tipo)
  },

  eliminar(idTipo) {
    return apiClient.delete(`/${idTipo}`)
  },

  guardar(tipo) {
    return apiClient.post('', tipo)
  },

  fichaTipoJSON(idTipo,params) {
    params = params || {}
    return apiClient.get(`${idTipo}.json`,{params:params})
  },

  fichasTiposJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/fichas/tipos.json`, { params: params })
  }
}