<template>
  <div class="modal fade" ref="mdlDetallesFicha" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">
              Detalles de la ficha
            </strong>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td style="width: 150px">
                        <strong>ID</strong>
                      </td>
                      <td style="width: 150px">
                        {{ficha.id}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Clave{{ficha.clave != null ? 's':''}} de acceso</strong>
                      </td>
                      <td>
                        <span v-show="ficha.clave == null">
                         <b>PIN:</b> {{ficha.pin}}
                        </span>
                        <span v-show="ficha.clave != null">
                          <b>Usuario:</b> {{ficha.pin}}
                          <b>Clave:</b> {{ficha.clave}}
                        </span>
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        <strong>Tiempo de la ficha</strong>
                      </td>
                      <td>
                        {{ ficha.tiempo == 0 ? 'No aplica' : convertidorSegundos_a_DHM(ficha.tiempo) }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tiempo de uso</strong>
                      </td>
                      <td>
                        {{tiempoUso}}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Tiempo restante</strong>
                      </td>
                      <td>
                        {{tiempoRestante}}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Primer uso de la ficha</strong>
                      </td>
                      <td>
                        {{ ficha.mt_inicio != null ? formatoFechaYHora(ficha.mt_inicio) : 'Sin usar' }}
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        <strong>Fecha y hora hasta cuando es válida</strong>
                      </td>
                      <td>
                        {{ ficha.mt_max_validez != null ? formatoFechaYHora(ficha.mt_max_validez) : 'No aplica' }}
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        <strong>Último login</strong>
                      </td>
                      <td>
                        {{ ficha.mt_login != null ? formatoFechaYHora(ficha.mt_login) : 'No ha hecho login' }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Último logout</strong>
                      </td>
                      <td>
                        {{ ficha.mt_logout != null ? formatoFechaYHora(ficha.mt_logout) : 'No ha hecho logout' }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Estado</strong>
                      </td>
                      <td>
                        <span
                          class="badge bg-soft-dark font-size-12"
                          v-show="ficha.mt_inicio == null"
                        >
                          Nueva
                        </span>
                        <span
                          class="badge bg-success bg-gradient font-size-12"
                          v-show="
                            ficha.mt_inicio != null && ficha.vencida == 0 &&
                            ficha.mt_login != null && ficha.mt_logout == null
                          "
                        >
                          En uso
                        </span>
                        <span
                          class="badge bg-warning bg-gradient font-size-12"
                          v-show="
                            ficha.mt_inicio != null && ficha.vencida == 0 &&
                            ficha.mt_login != null && ficha.mt_logout != null
                          "
                        >
                          Pausada
                        </span>
                        <span
                          class="badge bg-primary bg-gradient font-size-12"
                          v-show="ficha.vencida == 1"
                        >
                          Finalizada
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <h4>Límite y consumo de bytes</h4>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">Límite programado</th>
                      <th class="text-center">Consumo total del usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Tráfico de carga</strong>
                      </td>
                      <td class="text-center">
                        {{ficha.limite_trafico_carga == null ? 'Ilimitada' : convertidorBit_a_kMG(ficha.limite_trafico_carga, 'byte')}}
                      </td>
                      <td class="text-center">
                        {{convertidorBit_a_kMG(ficha.trafico_carga, 'byte')}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tráfico de descarga</strong>
                      </td>
                      <td class="text-center">
                        {{ficha.limite_trafico_descarga == null ? 'Ilimitada' : convertidorBit_a_kMG(ficha.limite_trafico_descarga, 'byte')}}
                      </td>
                      <td class="text-center">
                        {{convertidorBit_a_kMG(ficha.trafico_descarga, 'byte')}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td class="text-center">
                        {{ficha.limite_trafico_total == null ? 'Ilimitada' : convertidorBit_a_kMG(ficha.limite_trafico_total, 'byte')}}
                      </td>
                      <td class="text-center">
                        {{convertidorBit_a_kMG(ficha.trafico_total, 'byte')}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal">
                <i class="fa fa-check"></i>
                Aceptar 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import SistemaSrv from '@/services/SistemaSrv.js'
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import moment from 'moment'
export default {
  name: 'MdlFichaDetalles',

  data() {
    return {
      idInterval: null,
      fechaYHora: null,
      ficha: {
        comision:0,
        eliminada_en_router:0,
        expiracion:0,
        id:null,
        id_hotspot:null,
        id_layout:null,
        id_perfil:null,
        id_tipo:null,
        id_vendedor:null,
        limite_trafico_carga:0, // velocidad de subida
        limite_trafico_descarga:0, // velocidad de descarga
        limite_trafico_total:0,
        mt_inicio:null,
        mt_login:null,
        mt_logout:null,
        mt_max_validez:null, // expira en 
        mt_venta:null,
        pin:null,
        precio:0,
        sinc:0,
        t_uso:0,
        tiempo:0, // limite de tiempo
        trafico_carga:0,
        trafico_descarga:0,
        trafico_total:0, // limi te trafico
        vencida:0,
        clave: null
      },
      fichatmpl: null
    }
  },

  computed: {
    segundosUso() {
      let self = this
      let ficha = self.ficha
      let segundosUso = ficha.t_uso 
      let dtInicial = null
      let dtFinal = null

      if ( ficha.mt_inicio == null ) segundosUso = 0 
      else if ( ficha.vencida == false && ficha.mt_login != null && ficha.mt_logout == null) {
        dtInicial = new Date(ficha.mt_login.replace(' ', 'T'))
        dtFinal = new Date(self.fechaYHora.replace(' ', 'T'))

        segundosUso += ( (dtFinal.getTime() - dtInicial.getTime()) / 1000 )
      }

      if ( segundosUso % 60 > 0 ) segundosUso = segundosUso - (segundosUso % 60) + 60

      return segundosUso
    },
    tiempoUso() {
      return this.convertidorSegundos_a_DHM(this.segundosUso) 
    },

    tiempoRestante() {
      let self = this
      let segundosRestantes = self.ficha.tiempo - self.segundosUso
      return self.convertidorSegundos_a_DHM(segundosRestantes) 
    }
  },
  created() {
    this.fichatmpl = Object.assign({}, this.ficha)
  },

  mounted() {
    let self = this
    // $(this.$refs.mdlDetallesFicha).on('hide.bs.modal', function(e){
    //   clearInterval(self.idInterval)
    //   Object.assign(self.ficha, self.fichatmpl)
    // })
  },

  methods: {
    convertidorBit_a_kMG:function(bites, tipo){
      if(bites == 0) return '0 bites';

      var gb = Math.floor((bites / 1073741824))

      if(gb >= 1) {
        return tipo == 'byte' ? gb + 'GB' : gb +'Gb'
      }

      var mb = Math.floor((bites / 1048576 ) % 1024)
      if(mb >= 1) {
        return tipo == 'byte' ? mb + 'MB' : mb + 'Mb'
      }

      var kb = Math.floor((bites / 1024 ) % 1024)
      if(kb >= 1) {
        return tipo == 'byte' ? kb + 'KB' : kb + 'Kb'
      }
    },


    convertidorSegundos_a_DHM(segundos) {
      let dhm = ''

      if(segundos < 0 ) segundos = 0;

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)


      if ( dias > 0 ) dhm += dias + ' dia' + (dias == 0 || dias > 1 ? 's': '')
      if ( horas > 0 && dhm != '' ) dhm += ', '
      if ( horas > 0 ) dhm += horas + ' hora' + (horas == 0 || horas > 1 ? 's': '')
      if ( minutos > 0 && dhm != '') dhm += ', '
      if ( minutos > 0 ) dhm += minutos + ' minuto' + (minutos == 0 || minutos > 1 ? 's' : '') 

      if ( dhm == '' ) dhm = '0 minutos'

      return dhm
    },

    cargarFicha(idFicha){
      var self = this

      FichasSrv.fichaJSON(idFicha).then(response => {
        let ficha = Object.assign({}, response.data)
        self.ficha = ficha
        self.$emit('ficha:actualizada', ficha)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoFechaYHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') + 
        ' a las ' + moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') + 'hrs.'
    },

    mostrar(idFicha) {
      var self = this

      var modal = new Modal(this.$refs.mdlDetallesFicha)
      modal.show()

      clearInterval(self.idInterval)
      
      // Carga de la ficha
      self.refrescarFechaYHora().then(response => {
        self.cargarFicha(idFicha)
      })

      // Programación de refrescado de la ficha
      self.idInterval = setInterval(function(){
        self.refrescarFechaYHora().then(response => {
          self.cargarFicha(idFicha)
        })
      }, 30000)
    },

    refrescarFechaYHora() {
      let self = this

      let prom = SistemaSrv.fechaYHora()

      prom.then(response => {
        self.fechaYHora = response.data
      })

      return prom
    }
  }
}
</script>

<style scoped>

</style>